import React, { useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import Img from "gatsby-image";
import Layout from "../layouts/layout";
import ProjectCard from "../components/project-card";
import ProjectsFilter from "../components/projects-filter";

const OurWork = ({ location, data: { hero, projects} }) => {

const [searchTerm, setSearchTerm] = useState(false);
const [currentProjects, filterProjects] = useState(projects.edges);
const [visibleProjects, setVisibleProjects] = useState(8);
    
const resetProjects = () => { filterProjects(projects.edges); setVisibleProjects(8); }

//filter out initial projects if set by router 
useEffect(() => {

    if (location.state.preset !== undefined) {
        filterProjects(projects.edges);
        setVisibleProjects(8);
        filterProjects(prev => prev.filter(({node}) => {
            return node.projectSectors && node.projectSectors.find( ({name}) => name === location.state.preset)
        }))
    } else {
        filterProjects(projects.edges);
        setVisibleProjects(8);
    }

}, [location.state]);

/*
filterProjects(prev => prev.filter(({node}) => {
    return node.projectSectors && node.projectSectors.find( ({name}) => name === preset)
}))
*/

hero.bottomFeature = (
    <div className="bg-blue-900 bg-opacity-50 py-8 -mt-8">
        <ProjectsFilter resetProjects={resetProjects} filterProjects={filterProjects} />
    </div>
);

return (

<Layout hero={hero}>
    <div className="container mx-auto mb-16">
        <div className="sm:flex flex-wrap justify-between our-work-projects">
            <Projects projects={currentProjects} visibleProjects={visibleProjects} />
        </div>
        <div className="text-center">
          <button className="inline-block uppercase pt-6 border-b-2 pb-1 text-xs border-mane-yellow text-gray-800 font-bold"
              onClick={() => setVisibleProjects(prev => prev + 8)}
          >
              View More
          </button>
        </div>
    </div>

</Layout>

)};

const Projects = ({projects, visibleProjects}) => {
    return (
        <>
        {projects.slice(0, visibleProjects).map(({node}) => (
            <ProjectCard key={node.id} node={node}/>
        ))}
        </>
    );
}

export const query = graphql`
    query ourWork {
       hero: contentfulOurWork {
            heroHeading {
                childMarkdownRemark {
                    html
                }
            }
            heroBackgroundImage {
                resize (width: 1440) {
                    src 
                }
            }
       }

       projects: allContentfulProjects (sort: {fields: sortPriority}) {
           edges {
              node {
                  id
                  slug
                  name
                  description {
                    childMarkdownRemark {
                        excerpt
                    }
                  }
                  client
                  consultant
                  startDate(formatString: "MMMM YYYY")
                  builtUpArea
                  projectDuration
                  location
                  sector
                  projectSectors {
                    name
                  }
                  status
                  picture {
                    title
                    fluid (maxHeight:850, quality: 60) {
                        ...GatsbyContentfulFluid_withWebp_noBase64
                    }
                  }
              }
           }
       }

   }
`;


export default OurWork;
